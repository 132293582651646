<template>
  <sweet-modal
    ref="modal"
    v-sweet-esc
    class="modal-class"
    title="Before you delete this article"
    data-tc-title-text="article delete"
  >
    <template v-if="value">
      <div data-tc-text="article delete">
        Are you absolutely sure you want to delete this article?
      </div>
    </template>
    <button
      slot="button"
      class="btn btn-link text-secondary"
      data-tc-article-cancel
      @click.prevent="close"
    >
      No
    </button>
    <button
      slot="button"
      class="btn btn-link text-danger"
      data-tc-confirm="article delete"
      @click.prevent="okDelete"
    >
      Yes
    </button>
  </sweet-modal>
</template>

<script>
import http from 'common/http';
import { SweetModal } from 'sweet-modal-vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    SweetModal,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    showPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['articles']),
  },
  methods: {
    ...mapActions(['fetchArticles']),
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
    okDelete() {
      if (this.value) {
        const params = { params: { id: this.value.id }};

        http
          .delete(`/articles/${this.value.slug}.json`, params)
          .then(res => {
            if (res) {
              this.close();
              this.fetchArticles({ search: this.search, category: this.selectedCategory });
              this.emitSuccess('Article deleted successfully!');
              this.$emit('article-deleted');
              if(this.showPage) {
                this.$router.push('/articles');
              }
            }
          })
          .catch(error => {
            if (error) {
              this.emitError(`Sorry, there was an issue deleting this Article. Please refresh the page and try again. ${error.response.data.message}`);
              this.close();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-class {
    z-index: 121;
  }
</style>
