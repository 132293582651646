import strings from 'mixins/string';

export default {
  mixins: [strings],
  data() {
    return {
      images: {
        desktop_image: "https://nulodgic-static-assets.s3.us-east-1.amazonaws.com/images/assets/desktop.svg",
        firewall_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/dongle.svg",
        imac_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/imac.svg",
        ipad_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/imac.svg",
        iphone_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/imac.svg",
        laptop_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/laptop.svg",
        macbook_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/macbook.svg",
        mobile_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/mobile.svg",
        other_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/other.svg",
        phone_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/phone.svg",
        phone_system_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/phone-system.svg",
        printer_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/printer.svg",
        router_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/router.svg",
        server_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/server.svg",
        software_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/software.svg",
        surface_image: "https://nulodgic-static-assets.s3.us-east-1.amazonaws.com/images/assets/surface.svg",
        switch_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/switch.svg",
        tablet_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/tablet.svg",
        thin_client_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/thin-client.svg",
        virtual_machine_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/virtual-machine.svg",
        wap_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/wap.svg",
        tv_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/tv.svg",
        windows_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/windows.svg",
        apple_device_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/asset_manual_entry_icon.svg",
        dongle_image: "https://nulodgic-static-assets.s3.amazonaws.com/images/assets/dongle.svg",
      },
    };
  },
  methods: {
    assetTypeImageSrc(asset) {
      return this.images[this.assetTypeImage(asset)];
    },
    assetTypeImage(asset) {
      if (asset) {
        const assetType = this.toSnakecase(asset.assetType);
        if (assetType) {
          const name = this.toSnakecase(asset.name);
          const isAppleOS = asset.assetSoftwares && (
            asset.assetSoftwares.filter(software => {
              return software.name && (
                software.name.toLowerCase() == "ios"
                || software.name.toLowerCase() == "osx"
                || software.name.toLowerCase() == "macos"
              );
            }).length > 0);
          const isNamedApple = name.indexOf('apple') >= 0;
          const isAppleManufactured = asset.manufacturer && asset.manufacturer.toLowerCase().indexOf("apple") >= 0;
          const hasAppleDetails = (isAppleOS || isNamedApple || isAppleManufactured);

          const default_types = ["hardware", "computer", "network", "desktop", "laptop", "server", "virtual_machine", "thin_client", 
                              "phone", "iphone", "ipad", "phone_system", "mobile_devices", "router", "switch", "firewall", "wap", "mobile", "tablet",
                              "printer", "tv", "dongle", "windows", "apple_device", "other"];

          if (assetType === "mobile") {
            if (hasAppleDetails || name.indexOf('iphone') >= 0) {
              return "iphone_image";
            }
          } else if (assetType === "tablet") {
            if (hasAppleDetails || name.indexOf('ipad') >= 0) {
              return "ipad_image";
            } else if (name.indexOf('surface') >= 0) {
              return "surface_image";
            }
          } else if (assetType === "desktop") {
            if (hasAppleDetails || name.indexOf('mac') >= 0) {
              return "imac_image";
            }
          } else if (assetType === "laptop") {
            if (hasAppleDetails || name.indexOf('macbook') >= 0) {
              return "macbook_image";
            }
          } else if (!default_types.includes(assetType.toLowerCase())) {
            return "other_image";
          }

          return `${assetType}_image`;
        }
      }
      return null;
    },
    getSourceIcon(source) {
      if (source === "probe") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/network-probe.svg";
      } else if (source === "uploaded" || source === "system_generated") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/imported.png";
      } else if (source === "manually_added") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/manual.png";
      } else if (source === "agent") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/discovery-agent.svg";
      } else if (source === "meraki") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/meraki.png";
      } else if (source === "ubiquiti") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/ubiquiti_logo.png";
      } else if (source === "aws") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/aws_logo.png";
      } else if (source === "selfonboarding") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/self-onboarding.svg";
      } else if (source === "azure") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/azure_assets_logo.png";
      } else if (source === "google") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/google_logo.png";
      } else if (source === "ms_intune") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/intune_logo.png";
      } else if (source === "azure_ad_devices") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/azure_ad.png";
      } else if (source === "merged_assets") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/merge-icon.svg";
      } else if (source === "kaseya") {
        return 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/kaseya.png';
      } else if (source === "kandji") {
        return 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/kandji.png';
      } else if (source === "jamf_pro") {
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logos/integrations-logos/jamf_pro.png";
      }
      return null;
    },
  },
};
