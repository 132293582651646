<template>
  <smart-list
    ref="smartList"
    :field="field"
    :value="value"
    :options-url="optionsUrl"
    plural-name="assets"
    single-name="an asset"
    :is-survey-module="isSurveyModule"
    :disabled="disabled"
    :required="required"
    @input="emitInput"
    @open="openLink"
  >
    <template slot="label">
      <slot name="label" />
    </template>

    <template #image="{ option }">
      <img
        :src="`${imageSrc(option)}`"
        class="asset-icon-img"
      >
    </template>
  </smart-list>
</template>

<script>
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import assetImages from 'mixins/asset_images';
import SmartList from './smart_list.vue';

export default {
  components: {
    SmartList,
  },
  mixins: [ permissionsHelper, fieldViewerInput, assetImages],
  computed: {
    optionsUrl() {
      if (this.field.customFormId) {
        return `/custom_forms/${this.field.customFormId}/managed_asset_options.json`;
      }
      return `/managed_asset_options.json`;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.emitInput(this.value);
    },
    emitInput(ids) {
      this.$emit('input', ids);
    },
    imageSrc(option){
      return option.imageThumbUrl ? option.imageThumbUrl : this.assetTypeImageSrc(option);
    },
    showErrors() {
      return this.$refs.smartList.showErrors();
    },
    openLink(resource) {
      window.open(`/managed_assets/${resource.id}`, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.asset-icon-img {
  height: 1.25rem;
  object-fit: contain;
  width: 1.875rem;
}
</style>
