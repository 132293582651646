import { parsePhoneNumber, validatePhoneNumberLength, getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";

export default {
  methods: {
    processPhoneNumber(number, countryCode, countryCodeNumber) {
      if (!number) {
        return "";
      }
      const code = countryCode || "US";
      const countryNumber = `+${(countryCodeNumber || 1)}`;
      let formattedNumber = `${number}`;

      const validationResult = validatePhoneNumberLength(formattedNumber, code);
      if (validationResult === "TOO_LONG") {
        const exampleNumber = getExampleNumber(code, examples);
        if (exampleNumber) {
          const maxLength = exampleNumber.nationalNumber.length;
          formattedNumber = formattedNumber.slice(0, maxLength);
        }
      }

      const phoneNumber = parsePhoneNumber(`${countryNumber}${formattedNumber}`, code);
      if (code === "US") {
        return phoneNumber.formatNational();
      }
      return phoneNumber.formatInternational();
    },
    formatPhoneNumber(number, countryCode, countryCodeNumber) {
      if (countryCode === "US") {
        return this.processPhoneNumber(number, countryCode, countryCodeNumber);
      }
      return number;
    },
    stripFormattedNumber(phoneNumber) {
      return Number(phoneNumber.replace(/\D/g, ""));
    },
    validatePhoneNumber(phoneNumber, countryCode) {
      return validatePhoneNumberLength(phoneNumber, countryCode);
    },
  },
};
