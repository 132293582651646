<template>
  <div>
    <div 
      v-for="(option, index) in options"
      :key="index"
      v-tooltip.left="{
        content: option,
        boundariesElement: 'body',
        show: (option && option.length > 20 && hoveredIndex == index),
        trigger: 'manual',
      }"
      class="d-flex"
      @mouseover="hoveredIndex = index"
      @mouseleave="hoveredIndex = null"
    >
      <input 
        :id="`checkbox-${field.id}-${index}`"
        :key="compKey"
        ref="input"
        type="checkbox"
        :value="option"
        :checked="isSelected(option)"
        @change="processInput"
      >
      <label
        class="ml-1 mb-0"
        :for="`checkbox-${field.id}-${index}`"
      >{{ option }}</label>
    </div>
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span> 
  </div>
</template>

<script>
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';

export default {
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
      default: () => {},
    }, 
    value: {
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      valueStr: [],
      hoveredIndex: null,
      compKey: true,
    };
  },
  computed: {
    options() {
      if (typeof this.field.options === "string") {
        return JSON.parse(this.field.options);
      }
      return this.field.options;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.setCheckboxFieldValue();
    },
    isSelected(opt) {
      if (this.value && this.value.length > 0) {
        return this.value.includes(opt);
      }

      return false;
    },
    processInput(e) {
      const {value} = e.target;
      const {checked} = e.target;
      if (checked) {
        this.valueStr.push(value);
      } else {
        const idx = this.valueStr.indexOf(value);
        if (idx > -1) {
          this.$emit("delete", this.valueStr[idx]);
          this.valueStr.splice(idx, 1);
        }
      }
      this.emitValue();
      this.compKey = !this.compKey;
    },
    emitValue() {
      this.errors = null;
      this.$emit("input", this.valueStr);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.valueStr || this.valueStr.length === 0) {
        msg = `Please enter a ${this.field.label}.`;
        this.$refs.input[this.$refs.input.length - 1].setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input[this.$refs.input.length - 1].setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>
