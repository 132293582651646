import Form from '../components/help_center/form.vue';
import Forms from '../components/help_center/forms.vue';
import KnowledgeBase from '../components/help_center/knowledge_base.vue';
import Workspaces from '../components/shared/public_workspaces/workspaces.vue';
import Faqs from '../components/help_center/faqs.vue';
import { trackRouteChange } from './mixins/route_tracking_mixin';
import sanitizeRoute from './mixins/sanitize_route_mixin';
import ArticleShow from '../components/knowledge_base/show.vue';

const router = new VueRouter({
  routes: [
    {
      path: '/workspaces/:workspace_id',
      component: Forms,
      name: 'forms',
    },
    {
      path: '/workspaces/:workspace_id/forms/:id',
      component: Form,
      name: 'form',
    },
    {
      path: '/forms/:id',
      component: Form,
      name: 'form',
    },
    {
      path: '/',
      component: Workspaces,
      name: 'workspaces',
    },
    {
      path: '/knowledge_base/workspaces/:workspace_id',
      component: KnowledgeBase,
      name: 'knowledge_base',
    },
    {
      path: '/knowledge_base/:slug/:workspace_id',
      component: ArticleShow,
      name: 'help_center_knowledge_base_show',
    },
    {
      path: '/faqs/workspaces/:workspace_id',
      component: Faqs,
      name: 'help_center_faqs',
    },
  ],

  hashbang: false,
  mode: 'history',
  base: '/help_center',
});

router.beforeEach((to, from, next) => sanitizeRoute(to, from, next));
router.beforeResolve((to, from, next) => trackRouteChange(to, from, next, 'open_help_ticket'));

export default router;
