import dates from 'mixins/dates';
import http from 'common/http';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      attachmentUploading: false,
    };
  },
  mixins: [dates],
  computed: {
    savingContent() {
      return this.attachmentUploading ? 'Processing attachment' : 'Saving Article';
    },
    disabledSubmit() {
      return !this.articleObject.title ||
             !this.articleObject.body ||
             !this.articleObject.category;
    },
  },
  methods: {
    ...mapMutations(['setHelpCenterSelectedArticle']),
    isWriteAnyArticle(article) {
      if (this.isSuperAdmin) {
        return true;
      }
      for (let index = 0; index < article.articleExpandedPrivileges?.length; index += 1) {
        const articleExpandedPrivilege = article.articleExpandedPrivileges[index];
        if (Number(this.$currentContributorId) === articleExpandedPrivilege.contributorId) {
          if(articleExpandedPrivilege.permissionType !== "read") {
            return articleExpandedPrivilege.permissionType;
          }
        }
      }
      return false;
    },
    updatedAt(article) {
      return article && article.updatedAt ? this.showDate(article.updatedAt) : null;
    },
    fetchPublicArticle(attrs = {}) {
      return http
        .get(`/knowledge_base/${attrs.slug}/${attrs.workspaceId}.json`)
        .then((res) => {
          this.setArticle(res.data);
          this.setArticleLoadingStatus(false);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error fetching the article. ${error.response.data.message}`);
        });
    },
    handleNewRouteSetup() {
      if (this.isNewRoute) {
        this.handleBasicUserAccess('Knowledge Base');
        this.initArticle();
      }
    },
    initArticle() {
      http
        .get(`/contributor_options.json`)
        .then((res) => {
          res.data.forEach((grp) =>  {
            let permission = '';
            if (grp.name === this.$currentContributorName || grp.name === 'Admins') {
              permission = 'write';
            } else if (grp.name === 'Everyone') {
              permission = 'read';
            }

            if (permission !== '') {
              this.articleObject.articlePrivileges.unshift({ contributor: { id: grp.id, name: grp.name }, permission });
            }
          });
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error while fetching article privileges. ${error}`);
        });
    },
    createOrEditArticle() {
      if (this.validateArticle()) {
        return this.isEditRoute ? this.saveArticle() : this.createArticle();
      }
      return null;
    },
    validateArticle() {
      if (this.articleObject.reviewDate && moment(this.articleObject.reviewDate) < moment()) {
        this.emitError('Review date cannot be in the past.');
        return false;
      }
      return true;
    },
    setViewPage(page) {
      this.viewPage = page;
    },
    setReviewDate(date) {
      this.articleObject.reviewDate = date;
    },
    setArticlePublicStatus(status) {
      this.articleObject.public = status;
    },
    setAttachmentUploading(status) {
      this.attachmentUploading = status;
    },
  },
};
