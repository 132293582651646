<template>
  <div class="position-relative">
    <div
      :id="id"
      class="trixWrapper"
      :class="{ 'comment-box-shadow ' : isAddComment }"
    >
      <trix-editor
        v-if="isHtShowPage"
        ref="trixEditor"
        class="trix-content border-class"
        :class="{ 'box-bottom-border' : isAddComment}"
        :input="inputId"
        image-upload-path="/"
        :data-tc-input-editor="inputId"
        @trix-attachment-add="uploadAttachment"
        @trix-attachment-remove="removeAttachment"
        @keyup="handleKeyUp"
        @keydown="handleKeyDown"
        @click="handleClickEvent"
        @trix-change="trixChange"
      />
      <div 
        v-if="showTitleBar"
        :class="{ 'bottom-class': isHtShowPage }"
      >
        <div 
          v-if="!isHtShowPage"
          class="title-bar"
        >
          <label class="title">
            {{ label }}
          </label>
        </div>
        <div 
          :class="{ 
            'icon-bar' : !isHtShowPage,
            'modern-icon-bar' : isHtShowPage,
          }"
        >
          <div 
            v-if="!isEmailTemplate"
            class="ml-2 clickable"
            @click="showPopUp('@')"
          >
            <span>@</span>
            <span
              v-if="!isHtShowPage"
              class="text-themed-base mt-1 small ml-n0.5 mr-0.5"
            >
              Mentions
            </span>
          </div>
          <div
            v-if="isEmailTemplate"
            class="ml-2 clickable"
            @click="showPopUp('x')"
          >
            <span class="text-handler">{</span>
            <span>x</span>
            <span class="text-handler">}</span>
            <span class="text-themed-base mt-1 small mr-0.5">Text Variables</span>
          </div>
          <span 
            class="divider my-1 mx-2"
          />
          <div 
            v-if="!isBasicAccess"
            class="ml-0.5 mr-2 clickable"
            @click="showPopUp('#')"
          >
            <span>
              <i class="genuicon-nav-resources not-as-small"/>
            </span>
            <span 
              v-if="!isHtShowPage"
              class="text-themed-base small"
            >
              Resources
            </span>
          </div>
          <div
            v-if="currentEntity && currentEntity.ticketNumber"
            class="ml-0.5 mr-2 clickable"
            :class="{ 'box-width': isHtShowPage }"
            @click="clearFormatting"
          >
            <span class="divider my-1 mx-2"/>
            <span>
              <i class="nulodgicon-ios-close-outline not-as-small"/>
            </span>
            <span class="text-themed-base small">Clear formatting</span>
          </div>
          <div
            v-if="isHtShowPage"
            class="d-flex w-100 bottom-class"
          >
            <slot/>
          </div>
        </div>
      </div>
      <trix-editor
        v-if="!isHtShowPage"
        ref="trixEditor"
        class="trix-content"
        :input="inputId"
        image-upload-path="/"
        data-tc-trix-editor
        :data-tc-input-editor="inputId"
        @trix-attachment-add="uploadAttachment"
        @trix-attachment-remove="removeAttachment"
        @keyup="handleKeyUp"
        @keydown="handleKeyDown"
        @click="handleClickEvent"
        @trix-change="trixChange"
        @paste="handlePaste"
      />
    </div>
    <span
      v-if="showFileSizeError"
      class="form-text small text-danger"
    >
      {{ fileSizeErrorText }} {{ fileSizeToCheck === 25000000 ? '25mb' : '10mb' }}.
    </span>
    <span
      v-if="!isMspForm && !helpCenter && !isHelpCenterMode && !showTitleBar && allowMentions"
      class="small text-muted"
    >
      <kbd class="bg-light rounded mr-1">@</kbd>to mention <kbd class="bg-light rounded ml-2 mr-1">#</kbd>to add Articles/Responses/Faqs
    </span>
    <div
      v-show="popUpOpened || (currentMention && !isMspForm && !helpCenter)"
      id="trix-popup"
      ref="mentionsPopup"
      class="popup-menu not-as-small"
    >
      <div
        v-for="entity in entities"
        :key="`${entity.type}:${entity.id}`"
        v-click-outside="closePopUp"
        class="entity-option"
        :class="selectedCss(entity)"
        @mouseover="selected = entity"
        @mouseout="selected = null"
        @click.stop.prevent="selectEntity(entity)"
      >
        <div class="pa-2 cursor--pointer">
          <img
            v-if="entity.linkableType == 'ManagedAsset'"
            class="mr-1"
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/drawer/drawer-assets.svg"
            width="14"
          >
          <img
            v-else-if="entity.linkableType == 'Contract'"
            class="mr-1"
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/drawer/drawer-contracts.svg"
            width="14"
          >
          <img
            v-else-if="entity.linkableType == 'HelpTicket'"
            class="mr-1"
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/drawer/drawer-helpdesk.svg"
            width="14"
          >
          <img
            v-else-if="entity.linkableType == 'TelecomService'"
            class="mr-1"
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/drawer/drawer-telecom.svg"
            width="14"
          >
          <img
            v-else-if="entity.linkableType == 'Vendor'"
            class="mr-1"
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/drawer/drawer-vendors.svg"
            width="14"
          >
          <i
            v-else-if="entity.linkableType == 'CompanyUser'"
            width="14"
            class="nulodgicon-person mr-1"
          />
          <i
            v-else-if="entity.linkableType == 'Group'"
            width="14"
            class="nulodgicon-person-stalker mr-1"
          />
          <i
            v-else-if="entity.linkableType == 'Location'"
            width="14"
            class="nulodgicon-location mr-1"
          />
          <span class="avatar-name">
            {{ entity.name }}
          </span>
        </div>
      </div>
      <div v-if="isMentions && !haveEntities && !showNoResultsMessage">
        <div class="py-3 px-2 text-center text-secondary cursor--default">
          Loading mentions...
        </div>
      </div>
      <div
        v-else-if="isMentions && showNoResultsMessage"
        class="text-center mb-0 px-3 py-4"
      >
        <span class="text-muted white-space-normal mb-0">
          <div class="mb-2">
            No item found
          </div>
          <div class="small">
            Please modify or clear your search
          </div>
        </span>
      </div>
      <div
        v-else-if="(showShowMore && isMentions) || (cannedOption === '@' && showShowMore)"
        class="entity-option"
        @click.stop.prevent="showMore"
      >
        <div class="text-center text-secondary small cursor--pointer ">
          <i
            class="mr-1 nulodgicon-plus-round"
            width="16"
          />
          Show More
        </div>
      </div>
      <div
        v-else-if="isMentions || cannedOption === '@'"
        class="entity-option cursor--default"
      >
        <div class="text-center text-secondary small">
          All results shown
        </div>
      </div>
    </div>

    <Teleport to="body">
      <response-select
        ref="responseSelect"
        @input="selectResponse"
      />
    </Teleport>

    <article-select
      v-if="isHelpDesk"
      ref="articleSelect"
      @input="selectArticle"
    />

    <Teleport to="body">
      <faq-select
        ref="faqSelect"
        @input="selectFaq"
      />
    </Teleport>

    <Teleport to="body">
      <survey-select
        ref="surveySelect"
        @input="selectSurvey"
      />
    </Teleport>

    <Teleport to="body">
      <sweet-modal
        ref="attachmentsErrorModal"
        v-sweet-esc
        title="Attachments Not Supported"
      >
        <template slot="default">
          <h5>Sorry, but attachments are not supported for this field.</h5>
        </template>
      </sweet-modal>
    </Teleport>
  </div>
</template>

<script>
import http from 'common/http';
import 'trix';
import 'trix/dist/trix.css';
import { mapGetters, mapActions } from 'vuex';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import { SweetModal } from 'sweet-modal-vue';
import permissionsHelper from 'mixins/permissions_helper';
import fileValidator from 'mixins/file_validator';
import vClickOutside from 'v-click-outside';
import ArticleSelect from './article_select.vue';
import FaqSelect from './faq_select.vue';
import SurveySelect from './survey_select.vue';
import ResponseSelect from './response_select.vue';

const mentionRegex = /[#@A-Z0-9]/i;
export default {
  name: 'TrixVue',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ArticleSelect,
    ResponseSelect,
    FaqSelect,
    SweetModal,
    SurveySelect,
  },
  mixins: [
    fileValidator,
    permissionsHelper,
  ],
  props: {
    id: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    currentEntity: {
      type: Object,
      default: null,
    },
    allowAttachments: {
      type: Boolean,
      default: true,
    },
    grabFocus: {
      type: Boolean,
      default: false,
    },
    attachableType: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMspForm: {
      type: Boolean,
      default: false,
    },
    isHelpCenterMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    showTitleBar: {
      type: Boolean,
      default: false,
    },
    allowMentions: {
      type: Boolean,
      default: true,
    },
    displaySurveySection: {
      type: Boolean,
      default: false,
    },
    isHtShowPage: {
      type: Boolean,
      default: false,
    },
    isAddComment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entities: [],
      currentMention: null,
      rect: null,
      selected: null,
      showOptions: false,
      showShowMore: true,
      showNoResultsMessage: false,
      options: ['response', 'Articles', 'FAQ'],
      showFileSizeError: false,
      fileSizeErrorText: 'File is too large. Please upload a file under',
      relatedItems: [],
      deletedAttachments: [],
      offset: 0,
      disableShowMore: false,
      trixData: "",
      fileUploading: false,
      attachmentUpdated: false,
      imageAttachments: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
      uploadedFiles: [],
      trixLastKey: null,
      fileSizeToCheck: 10000000,
      activeOptionIndex: -1,
      popUpOpened: false,
      cannedOption: null,
      editorActive: false,
      textVarible: false,
    };
  },

  computed: {
    ...mapGetters({
      locations: 'customForms/locations',
      contracts: 'customForms/contracts',
      vendors: 'customForms/vendors',
      telecoms: 'customForms/telecoms',
      assets: 'customForms/assets',
      contributors: 'customForms/contributors',
      helpTickets: 'customForms/helpTickets',
    }),
    ...mapGetters(['validExtensions']),
    haveEntities() {
      return this.entities && this.entities.length > 0;
    },
    isOptions() {
      const mention = _get(this, "currentMention", "");
      return mention && mention.indexOf("#") === 0;
    },
    isMentions() {
      const mention = _get(this, "currentMention", "");
      return mention && mention.indexOf("@") === 0;
    },
    helpCenter() {
      return this.$router.options.base === '/help_center';
    },
    isHelpTicket() {
      return this.$router.options.base === '/help_tickets';
    },
    isHelpDeskModule() {
      return this.isHelpTicket || this.helpCenter;
    },
    scrollPosition() {
      const optionHeight = 37; // this is the approx height of the option in the dropdown
      return this.activeOptionIndex * optionHeight;
    },
    isEmailTemplate() {
      return ['email-templates'].includes(this.$route.name);
    },
  },
  watch: {
    relatedItems() {
      this.$emit('related-items', this.relatedItems);
    },
    trixData() {
      const sources =  this.getAttrFromString(this.trixData, "img", "src");
      if (this.fileUploading && !sources.find(s => s.includes("blob")) && !this.uploadedFiles.some(file => file.status === "pending")) {
        this.$emit('update-uploading-status', false);
        this.fileUploading = false;
      }
    },
    scrollPosition(newPosition) {
      this.$refs.mentionsPopup.scrollTop = newPosition;
    },
    disabled(newValue) {
      if (this.$refs.trixEditor) {
        this.$refs.trixEditor.contentEditable = !newValue;
      }
    },
  },
  updated() {
    if (this.currentMention) {
      this.$nextTick(() => {
        this.positionPopup(this.$refs.mentionsPopup);
      });
    }
  },

  methods: {
    ...mapActions(['fetchValidExtensions']),

    onWorkspaceChange() {
      if (this.disabled && this.$refs.trixEditor) {
        this.$refs.trixEditor.contentEditable = false;
      }

      if (!this.allowAttachments) {
        this.$el.querySelector(".trix-button-group--file-tools").classList.add("hidden");
      }

      if (this.grabFocus) {
        this.$refs.trixEditor.focus();
      }
      if (this.isHelpDeskModule && !this.validExtensions.length) {
        this.fetchValidExtensions();
      }
    },
    trixChange(e) {
      if (this.fileUploading) {
        this.trixData = e.currentTarget.innerHTML;
      }
      this.$emit('input', e.currentTarget.innerHTML);
    },
    handleClickEvent(e) {
      if (e.toElement && e.toElement.href) {
        window.open(e.toElement.href, "_blank");
      }
      if (e.target && e.target.href) {
        window.open(e.target.href, "_blank");
      }
    },

    selectedCss(entity) {
      if (this.selected === entity) {
        return {'entity-selected': true};
      }
      return {};
    },

    selectOption(option) {
      // This shouldnt' be possible but just in case...
      if (!this.isMspForm) {
        this.closePopUp();
        if(!this.popUpOpened) this.removePound();
        if (option.name === 'Add Canned Responses') {
          this.$refs.responseSelect.open();
        } else if (option.name === 'Add KB Articles') {
          this.$refs.articleSelect.open();
        } else if (option.name === 'Add FAQs') {
          this.$refs.faqSelect.open();
        } else if (option.name === 'Add Surveys') {
          this.$refs.surveySelect.open();
        }
      }
    },

    isFileTypeAllowed(file) {
      if (this.isHelpDeskModule) {
        return this.validExtensions.includes(this.getFileExtension(file));
      }
      
      return this.isAllowedFileType(file);
    },

    uploadAttachment(event) {
      const { file } = event.attachment;
      const preloadingURL = event.attachment?.attachment?.preloadingURL;
      if (preloadingURL && !preloadingURL.startsWith('blob') && !file) {
        return false;
      }
      if (!this.allowAttachments) {
        event.stopPropagation();
        event.preventDefault();
        event.attachment.remove();
        this.$refs.attachmentsErrorModal.open();
        return false;
      }
      this.showFileSizeError = false;

      if (!file) {
        return event.attachment.remove();
      }
      if (!this.isFileTypeAllowed(file)) {
        event.stopPropagation();
        event.preventDefault();
        event.attachment.remove();
        this.emitError('Invalid file format.');

        return false;
      }

      this.$emit('update-uploading-status', true);
      const serializedFile = new FormData();
      this.fileSizeToCheck = this.getFileExtension(file) === '.mp4' ? 25000000 : 10000000;

      if (file.size > this.fileSizeToCheck) { // 25mb for .mp4, 10mb for other files
        this.showFileSizeError = true;
        event.attachment.remove();
        this.$emit('update-uploading-status', false);
        return false;
      }

      this.fileUploading = true;

      serializedFile.append('attachment', file, file.filename);
      serializedFile.append('attachable_type', this.attachableType);
      if (!this.imageAttachments.includes(file.type)) {
        this.uploadedFiles.push({name: file.name, status: "pending", type: file.type});
      }

      http
        .post('/attachment_uploads.json', serializedFile, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength) {
              const progressData = Math.round((progressEvent.loaded * 100) / totalLength);
              event.attachment.setUploadProgress(progressData);
            }
          },
        })
        .then(res => {
          if (res.data.message) {
            this.emitError(res.data.message);
            event.attachment.remove();
            this.$emit('update-uploading-status', false);
            this.fileUploading = false;
          } else {
            event.attachment.setAttributes({
              url: res.data.attachment.url,
              target: '_blank',
              attachmentId: res.data.attachment.id,
            });

            this.$emit('handle-attachment-add', res.data);
            const attType = res.data.attachment.attachmentContentType;
            if (!this.imageAttachments.includes(attType)) {
              this.updateAttachment(res.data.attachment);
              // Enables/disables the save button according to attachments' upload progress
              this.uploadedFiles.find(obj => obj.name === res.data.attachment.attachmentFileName && obj.status === "pending").status = "complete";
              if (!this.uploadedFiles.some(obj => obj.status === "pending")) {
                this.$emit('update-uploading-status', false);
                this.fileUploading = false;
              }
            }
          }
          return false;
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response) {
            errorMessage = error.response.data.message;
          }
          event.attachment.remove();
          this.emitError(`Sorry, there was an error uploading this attachment. ${errorMessage}`);
          const attIndex = this.uploadedFiles.findIndex((att) => att.name === event.attachment.obj.name);
          if (attIndex > -1) {
            this.uploadedFiles.splice(attIndex, 1);
          }
          this.$emit('update-uploading-status', false);
          this.fileUploading = false;
        })
        .finally(() => {
          event.attachment.setUploadProgress(100);
          const { editor } = this.$refs.trixEditor;
          const len = editor.getDocument().toString().length;
          editor.setSelectedRange(len - 1);
        });
      return false;
    }, 
    removeAttachment(event) {
      if (!this.attachmentUpdated){
        const { file } = event.attachment;
        const { attachmentId } = event.attachment.attachment.attributes.values;
        const isAlreadyDeleted = this.deletedAttachments.includes(attachmentId);
        if (file && attachmentId && !isAlreadyDeleted) {
          this.deletedAttachments.push(attachmentId);
          this.$emit('update-uploading-status', true);
          http
            .delete(`/attachment_uploads/${attachmentId}.json`)
            .then(() => {
              this.$emit('handle-attachment-remove', attachmentId);
            })
            .catch(error => {
              this.emitError(`Sorry, there was an error removing this attachment. ${error.response.data.message}`);
            })
            .finally(() => this.$emit('update-uploading-status', false));
        } else if (attachmentId && !isAlreadyDeleted) {
          this.$emit('deleted-attachmet-id', attachmentId);
        }
      }
      this.attachmentUpdated = false;
    },

    removePound() {
      const position = this.poundPosition();
      if (position > -1) {
        const { editor } = this.$refs.trixEditor;
        editor.setSelectedRange([position, position + 1]);
        editor.insertHTML("");
      }
    },

    poundPosition() {
      const { editor } = this.$refs.trixEditor;
      const pos = editor.getPosition();
      const text = editor.getDocument().toString();
      const idx = pos - 1;
      if (text.substring(idx, pos) === "#") {
        return idx;
      }
      return -1;
    },

    mentionRange() {
      const start = this.mentionStart();
      if (start > -1) {
        const end = this.mentionEnd(start);
        if (end > start) {
          return [start, end];
        }
      }
      return null;
    },

    moveDown(options) {
      if (this.selected == null) {
        [this.selected] = options;
      } else if (this.selected === options[options.length - 1]) {
        this.selected = options[options.length - 1];
      } else {
        const idx = options.indexOf(this.selected);
        if (idx > -1 && idx < options.length) {
          this.selected = options[idx + 1];
        }
      }
      this.activeOptionIndex = options.indexOf(this.selected);
    },

    moveUp(options) {
      if (this.selected == null) {
        this.selected = options[options.length - 1];
      } else if (this.selected === options[0]) {
        [this.selected] = options;
      } else {
        const idx = options.indexOf(this.selected);
        if (idx > -1 && idx < (options.length)) {
          this.selected = options[idx - 1];
        }
      }
      this.activeOptionIndex = options.indexOf(this.selected);
    },
    handleKeyUp(e) {
      if (this.currentMention) {
        const keys = ['ArrowDown', 'ArrowUp', 'Enter', 'Tab', 'Escape', 'Shift'];
        if (keys.includes(e.key)) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          this.offset = 0;
          this.refreshMention();
        }
      } else {
        this.refreshMention(e.key);
      }
      return true;
    },
    handleKeyDown(e) {
      if (this.currentMention) {
        const keys = ['ArrowDown', 'ArrowUp', 'Enter', 'Tab'];
        if (keys.includes(e.key)) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === 'ArrowDown') {
          this.moveDown(this.entities);
        } else if (e.key === 'ArrowUp') {
          this.moveUp(this.entities);
        } else if (e.key === 'Enter' || e.key === 'Tab') {
          this.selectEntity(this.selected);
        } else if (e.key === 'Escape') {
          this.refreshMention(e.key);
        }
      }
      return true;
    },

    rangeOnClick(){
      const { editor } = this.$refs.trixEditor;
      const lastIndex = editor.getDocument().toString().length - 1;
      return [lastIndex , lastIndex+2]; 
    },
    mentionStart() {
      const { editor } = this.$refs.trixEditor;
      const pos = editor.getPosition();
      const text = editor.getDocument().toString();
      let idx = pos - 1;
      while (idx > -1) {
        if (text[idx] === "@" || text[idx] === "#") {
          return idx;
        }
        idx -= 1;
      }

      return -1;
    },

    mentionEnd(start) {
      const { editor } = this.$refs.trixEditor;
      const text = editor.getDocument().toString();
      let idx = start + 1;
      while (idx < text.length) {
        if (!mentionRegex.test(text[idx])) {
          return idx + 1;
        }
        // This is just in case.  Again, not likely but...
        if ((idx - start) > 40) {
          return idx + 1;
        }
        idx += 1;
      }

      if (idx > start) {
        return idx + 1;
      }
      return -1;
    },

    showPopUp(key) {
      if (this.popUpOpened && (this.cannedOption === key || this.textVarible)) {
        this.closePopUp();
      } else {
        this.closePopUp();
        this.popUpOpened = true;
        this.$refs.mentionsPopup.style.top = `${3.75}rem`;
        this.$refs.mentionsPopup.style.marginTop = `${0}px`;
        if (key === "#") this.$refs.mentionsPopup.style.left = `${6}rem`;
        if (key === 'x') {
          this.textVarible = true;
          this.loadTextReplacements();
        } else if (key === "#") {
          this.loadOptions();
        } else if (key === "@") {
          this.loadMentionables();
        }

        if (key === "@") {
          this.editorActive = true;
          this.$refs.trixEditor.focus();
          this.editorInsert(key);
        }
      }
    },

    editorInsert(key) {
      if (this.popUpOpened && this.editorActive) {
        const { editor } = this.$refs.trixEditor;
        editor.insertString(key);
      }
    },

    closePopUp() {
      this.popUpOpened = false;
      this.showShowMore = true;
      this.cannedOption = null;
      this.editorActive = false;
      this.textVarible = false;
      this.clearPopup();
    },

    refreshMention(key = "") {
      this.trixLastKey = key;
      const { editor } = this.$refs.trixEditor;
      this.currentMention = this.mention(editor, key);
      if (this.currentMention) {
        if (!this.rect) {
          this.rect = editor.getClientRectAtPosition(editor.getPosition() - 1);
        }
        if (this.currentMention[0] === "@" && !this.helpCenter) {
          this.loadMentionables();
        } else {
          this.loadOptions();
        }
      } else {
        this.clearPopup();
      }
    },

    mention(editor, key) {
      let pos = editor.getPosition();
      let text = editor.getDocument().toString().replace(/\n/g, ' ');
      if (text && text.split(' ').length > 1) {
        text = text.split(' ').filter(str => /\S/.test(str)).pop();
      } else if (text && text.split(' ').length === 1 && text.split("").reverse().join("")[0] === ' ') {
        text = '';
      }
      let idx = pos;
      if (key === 'Backspace') {
        idx -= 1;
        pos -= 1;
      }

      if (key === "@" || key === "#") {
        return key;
      }
      if (key === "Escape") {
        text = '';
      }

      while (idx > -1) {
        let mention = text;
        if (mentionRegex.test(key) && key.length === 1) {
          mention += key;
        }
        if (mention && (mention[0] === "@" || mention[0] === "#")) {
          return mention.substring(0);
        }
        if (mention && !mentionRegex.test(mention)) {
          this.clearPopup();
          return null;
        }
        idx -= 1;
      }
      this.clearPopup();
      if (this.editorActive) {
        this.closePopUp();
      }
      return null;
    },

    showMore() {
      this.offset += 20;
      this.loadLinkables();
    },

    clearPopup() {
      this.offset = 0;
      this.entities = null;
      this.rect = null;
      this.currentMention = null;
      if (this.$refs.mentionsPopup) {
        this.$refs.mentionsPopup.style.top = null;
        this.$refs.mentionsPopup.style.left = null;
      }
    },

    loadTextReplacements() {
      this.popUpOpened = true;
      http
        .get('/string_interpolation_keys.json', { params: { type: 'HelpTicket' } })
        .then((res) => {
          const dataArray = res.data;
          const objectArray = dataArray.map((element, index) => ({
            id: index + 1,
            name: `{${element}}`,
          }));
          this.entities = objectArray;
        })
        .catch(() => {
          this.emitError('Sorry, there was an error loading text replacements');
        });
    },

    loadOptions() {
      this.showShowMore = false;
      const options = [
        { id: 1, name: 'Add Canned Responses'},
        { id: 2, name: 'Add KB Articles'},
        { id: 3, name: 'Add FAQs'},
      ];
      if (this.displaySurveySection) {
        options.push({ id: 4, name: 'Add Surveys'});
      }
      const filter = this.popUpOpened ? '' :  this.currentMention.substring(1).toLowerCase();
      this.entities = options.filter(option => option.name.toLowerCase().includes(filter));
    },

    loadLinkables() {
      const searchable = this.popUpOpened ? '' : this.currentMention.slice(1);
      const params = { search: searchable, offset: this.offset, limit: 20 };
      http
        .get('/linkable_options.json', { params })
        .then((res) => {
          if (res.data.length > 0) {
            this.entities = this.entities.concat(res.data);
          } else {
            this.showShowMore = false;
          }
        })
        .catch(() => {
          this.emitError('Sorry, there was an error loading mentions. Please try again later.');
        });
    },

    loadMentionables: _debounce(function loadMentionables() {
      let searchable = "";
      if (this.currentMention) {
        searchable = this.currentMention.slice(1);
      }
      const params = { search: searchable, offset: this.offset, limit: 20 };
      this.showNoResultsMessage = false;

      http 
        .get('/linkable_options.json', { params })
        .then((res) => {
          const { editor } = this.$refs.trixEditor;
          const lastMentionKey = this.popUpOpened ? null : this.mention(editor, this.trixLastKey);
          if (this.popUpOpened || (lastMentionKey && lastMentionKey[0] === '@')) {
            this.entities = res.data;
            [this.selected] = this.entities;
            this.showShowMore = res.data.length === 20;

            if (this.entities.length === 0 ) {
              this.showNoResultsMessage = true;
            }
            if (this.popUpOpened) {
              this.cannedOption = '@';
            }
          }
        })
        .catch(() => {
          this.emitError('Sorry, there was an error loading mentions. Please try again later.');
        });
    }, 400),

    concatEntities(opt) {
      this.entities = this.entities.concat(opt);
    },

    positionPopup(popup) {
      if (popup) {
        const popUp = popup;
        const { editor } = this.$refs.trixEditor;
        this.rect = editor.getClientRectAtPosition(editor.getPosition());
        if (this.rect) {
          const trixRect = this.$refs.trixEditor.getBoundingClientRect();
          const y = this.rect.top - trixRect.top + this.rect.height + 16;
          let x = this.rect.left - trixRect.left;
          popUp.style.top = `${y}px`;
          popUp.style.left = `${x}px`;
          const popupRect = popup.getBoundingClientRect();
          if (popupRect.width < 100 && popupRect.width > 0) {
            x -= (200 - popupRect.width);
          }
          popUp.style.left = `${x}px`;
        }
      }
    },

    selectEntity(entity) {
      // This shouldnt' be possible but just in case...
      if (!entity) return;
      if (this.currentMention && this.currentMention[0] === "@") {
        this.selectMentionable(entity);
      } else if (this.cannedOption === "@") {
        this.selectMentionable(entity);
      } else if (this.textVarible) {
        this.selectTextReplacement(entity);
      } else {
        this.selectOption(entity);
      }
     },

    selectTextReplacement(entity) {
      if (this.popUpOpened && this.textVarible) {
        const { editor } = this.$refs.trixEditor;
        editor.insertString(`${entity.name}`);
        this.closePopUp();
      }
    },

    selectMentionable(entity) {
      let href = null;
      const { name } = entity;
      const basePath = window.location.origin;
      if (entity.linkableType === 'HelpTicket') {
        href = `${basePath}/help_tickets/${entity.linkableId}`;
      } else if (entity.linkableType === 'ManagedAsset') {
        href = `${basePath}/managed_assets/${entity.linkableId}`;
      } else if (entity.linkableType === 'Contract') {
        href = `${basePath}/contracts/${entity.linkableId}`;
      } else if (entity.linkableType === 'Vendor') {
        href = `${basePath}/vendors/${entity.linkableId}`;
      } else if (entity.linkableType === 'TelecomService') {
        href = `${basePath}/telecom_services/${entity.linkableId}`;
      } else if (entity.linkableType === 'CompanyUser') {
        href = `${basePath}/company/users/${entity.linkableId}`;
      } else if (entity.linkableType === 'Group') {
        href = `${basePath}/company/groups/${entity.linkableId}/edit`;
      } else if (entity.linkableType === 'Location') {
        href = `${basePath}/company/locations/${entity.linkableId}`;
      }
      this.relatedItems.push(entity);
      const innerHTML = `<a href="${href}" target="_blank"><span class="text-underline">${name}</span></a>`;
      let range;
      if (this.editorActive) {
        range = this.mentionRange();
      } else {
        range = this.popUpOpened ? this.rangeOnClick() : this.mentionRange();
      }

      if (range) {
        const { editor } = this.$refs.trixEditor;
        editor.setSelectedRange(range);
        editor.insertHTML(innerHTML);
        editor.moveCursorInDirection("forward");
        this.refreshMention();
        this.closePopUp();
      }
    },
    setResponse(text) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      const { editor } = this.$refs.trixEditor;

      doc.body.childNodes.forEach(node => {
        const tempDiv = document.createElement('div');
        tempDiv.appendChild(node.cloneNode(true));
        editor.insertHTML(tempDiv.innerHTML);
      });
    },

    selectResponse(response) {
      this.$refs.responseSelect.close();
      if (this.currentEntity) {
        const entityClassName = this.currentEntity.ticketNumber ? "HelpTicket" : "";
        const data = {
          entity_id: this.currentEntity.id,
          entity_class: entityClassName,
          key: response.description,
        };
        http
          .post('/string_interpolation_keys/value.json', data)
          .then((res) => {
            this.setResponse(res.data);
          })
          .catch(error => {
            this.emitError(`Sorry, there was a problem with getting your response (${error.message}).`);
          });
      } else {
        this.setResponse(response.description);
      } 
    },

    selectFaq(faq) {
      this.$refs.faqSelect.close();
      const href = `${window.location.origin}/help_tickets/faqs/${faq.id}`;
      const div = document.createElement("div");
      div.innerHTML = faq.questionBody;
      const innerHTML = `<a href="${href}" target="_blank"><span>${div.innerText}</span></a>`;
      const {editor} = this.$refs.trixEditor;
      const pos = editor.getPosition();
      const range = [pos, pos + 2];
      if (range) {
        // eslint-disable-next-line no-shadow
        const {editor} = this.$refs.trixEditor;
        editor.setSelectedRange(range);
        editor.insertHTML(innerHTML);
      }
    },

    selectArticle(article) {
      this.$refs.articleSelect.close();
      const href = `${window.location.origin}/help_tickets/articles/${article.slug}`;
      const div = document.createElement("div");
      div.innerHTML = article.title;
      const innerHTML = `<a href="${href}" target="_blank"><span>${div.innerText}</span></a>`;
      const {editor} = this.$refs.trixEditor;
      const pos = editor.getPosition();
      const range = [pos, pos + 2];
      if (range) {
        // eslint-disable-next-line no-shadow
        const {editor} = this.$refs.trixEditor;
        editor.setSelectedRange(range);
        editor.insertHTML(innerHTML);
      }
    },

    getAttrFromString(str, node, attr) {
      const regex = new RegExp(`<${node} .*?${attr}="(.*?)"`, "gi");
      let result;
      const res = [];
      result = regex.exec(str);
      while (result) {
        res.push(result[1]);
        result = regex.exec(str);
      }
      return res;
    },
    updateAttachment(attachment) {
      // Updates the trix generated html with links for added attachments
      const htmlLink = document.createElement('div');
      const { editor } = this.$refs.trixEditor;
      const filteredFigures = Object.values(editor.element.getElementsByTagName("figure")).filter(fig => !this.imageAttachments.includes(fig.dataset.trixContentType));
      const figuersCount = filteredFigures.length;
      const lastFigure = filteredFigures[figuersCount - 1];
      const innerHTML = `<div><a href="${attachment.url}" target="_blank"><span>${attachment.attachmentFileName}</span></a></div>`;
      htmlLink.innerHTML = innerHTML;
      const pos = editor.getPosition();
      const range = [pos, pos + 2];
      if (range) {
        editor.setSelectedRange(range);
        lastFigure.replaceWith(htmlLink);
        this.attachmentUpdated = true;
      }
    },
    clearFormatting() {
      const { editor } = this.$refs.trixEditor;
      let rawHTML = editor.getDocument().toString();
      if (rawHTML.endsWith('\n')) {
        rawHTML = rawHTML.slice(0, -1);
      }
      const plainText = rawHTML.replace(/<\/?[^>]+(>|$)/g, "");
      editor.setSelectedRange([0, editor.getDocument().getLength()]);
      editor.deleteInDirection("forward");
      editor.insertString(plainText);
    },
    selectSurvey(survey) {
      this.$refs.surveySelect.close();
      const ticketReference = this.currentEntity?.ticketNumber ? `?ticket_id=${this.currentEntity.id}` : '';
      const href = `${window.location.origin}/custom_surveys/${survey.id}${ticketReference}`;
      const div = document.createElement("div");
      div.innerHTML = survey.trigger.buttonText || survey.title;
      const innerHTML = `<a href="${href}" target="_blank"><span>${div.innerText}</span></a>`;
      const {editor} = this.$refs.trixEditor;
      const pos = editor.getPosition();
      const range = [pos, pos + 2];
      if (range) {
        // eslint-disable-next-line no-shadow
        const {editor} = this.$refs.trixEditor;
        editor.setSelectedRange(range);
        editor.insertHTML(innerHTML);
      }
    },
    handlePaste(event) {
      const { editor } = this.$refs.trixEditor;
      const clipboardData = event.clipboardData || window.clipboardData;

      if (!clipboardData) return;

      const containsImage = Array.from(clipboardData.items).some((item) =>
        item.type.startsWith("image/")
      );
      if (containsImage) return; // Let Trix handle image pasting

      event.preventDefault();
      navigator.clipboard.readText().then((clipboardText) => {
        try {
          const article = JSON.parse(clipboardText);
          if (article && article.slug) {
            const href = `${window.location.origin}/help_tickets/articles/${article.slug}`;
            const selectedText = document.getSelection().toString();

            const div = document.createElement("div");
            div.innerHTML = selectedText === '' ? article.title : selectedText ;

            const innerHTML = `<a href="${href}" target="_blank"><span>${div.innerText}</span></a>`;
            const range = editor.getSelectedRange();

            if (range && range.length === 2) {
              editor.setSelectedRange(range);
              editor.insertHTML(innerHTML);
            }
          } else {
            editor.insertString(clipboardText);
          }
        } catch {
          // If invalid JSON, paste normally
          editor.insertString(clipboardText);
        }
      });
    },
  },
};
</script>

<style src="trix/dist/trix.css"></style>

<style lang="scss" scoped>

  .title {
    color: $white;
    text-align: left;
    margin-top: 0.3125rem;
    margin-left: 0.625rem;
  }

  .title-bar {
    border-radius: $border-radius $border-radius 0 0 !important;
    background-color: $dark-drawer; 
    height: 1.875rem;
  }

  .icon-bar {
    align-items: center;
    background-color: $themed-light; 
    display: flex;
    height: 1.875rem;
    .genuicon-nav-resources {
      position: relative;
      top: .125rem;
    }
  }

  .modern-icon-bar {
    align-items: center;
    display: flex;
    height: 3rem;
    border: 0.063rem solid #ccc;
    border-top: 0rem;
    border-bottom: 0rem;
    box-shadow: 0rem 0.125rem 0rem 0rem #ccc;
    border-radius: 0rem 0rem 0.563rem 0.563rem;
    .genuicon-nav-resources {
      position: relative;
      top: .125rem;
    }
  }

  .topbar{
    border-top-left-radius: $border-radius; 
    border-top-right-radius: $border-radius;
  }

  .divider {
    border-right: 1px solid var(--themed-light-hover-bg);
    height: 60%; 
    position: relative;
    top: 0.0625rem;
  }

  .popup-menu {
    background: $themed-box-bg;
    border: 1px solid $border-color;
    border-bottom: 0px !important;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: $shadow-base;
    max-height: 200px;
    margin-top: -1rem;
    min-height: 100px;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    z-index: 200;
    bottom: 0;
  }

  .entity-selected {
    background-color: $teal;

    .avatar-name {
      color: $themed-lighter;
    }
  }

  .entity-option {
    padding: 8px;
  }

  .avatar-name {
    color: $themed-base;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 180px;
    word-break: break-word;
  }

  .cursor--pointer {
    cursor: pointer;
  }

  .cursor--default {
    cursor: default;
  }

  .text-handler {
    font-size: 0.8rem;
    padding-top: 1rem !important;
  }
  .box-width {
    white-space: nowrap;
  }
  .border-class {
    overflow-x: auto; 
    max-height: 20px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
  }
  .bottom-class {
    bottom: 0 !important;
  }
  .box-bottom-border {
    border-bottom: 0 !important;
  }
  .comment-box-shadow {
    box-shadow: 
        4px 4px 6px -2px rgba(0, 0, 0, 0.12),
       -4px 4px 6px -2px rgba(0, 0, 0, 0.12),
        0px 4px 6px -2px rgba(0, 0, 0, 0.12);
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}
</style>
