import http from "common/http.js";

export default {
  data() {
    return {
      emailRequestSent: false,
      mfaPhoneNumber: {
        phoneNumber: '',
        phoneNumberCountryCode: '',
        phoneNumberCountryCodeNumber: '',
      },
    }
  },
  methods: {
    smsNumber() {
      if (this.mfaPhoneNumber.phoneNumber) {
        let country_code = this.mfaPhoneNumber.phoneNumberCountryCodeNumber;
        let mobile_phone = this.mfaPhoneNumber.phoneNumber;
        return `+${country_code || 1}${mobile_phone}`;
      }
      return null;
    },
    phoneSlicer(registeredDevice=false) {
      let phone = "";
      if (registeredDevice)
        phone = registeredDevice;
      else
        phone = this.mfaPhoneNumber.phoneNumber;
      return phone.toString().substr(-4, 4);
    },
    updatePhone(phoneData) {
      this.mfaPhoneNumber.phoneNumber = phoneData.phone;
      this.mfaPhoneNumber.phoneNumberCountryCode = phoneData.countryCode;
      this.mfaPhoneNumber.phoneNumberCountryCodeNumber = phoneData.countryCodeNo;
    },
    sendCodeViaEmail() {
      this.mfaCode = null;
      this.error = false;
      this.resendCodeRequested = true;
      this.$refs.codeInput.reset();
      this.emailRequestSent = true;
      http
        .get(`/mfa/send_code_via_email.json`)
        .then(() => {
          this.emitSuccess("Code successfully sent to your email.");
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error while sending code to email address.`);
        })
        .finally(()=> {
          this.resendCodeRequested = false;
        });
    },
    sendCodeViaText(params) {
      this.isloading = true;
      http
        .get(`/mfa/send_code_via_text.json`, { params })
        .then((res) => {
          if (res.data.message)
            this.emitSuccess(res.data.message);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error while sending code to device.`);
        })
        .finally(()=> {
          this.resendCodeRequested = false;
          this.isloading = false
        });
    },
    authenticateViaApp() {
      this.error = false;
      http
        .get(`/mfa/authenticate_via_app.json`)
        .then((res) => {
          this.qrUrl = res.data.secretKey;
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error while generating the authentication QR code.`);
        })
        .finally(()=> {
          this.resendCodeRequested = false;
        });
    },
  },
}
