<template>
  <div class="d-flex modal-title">
    <img
      :src="imageSrc"
      height="90px"
    >
    <div
      class="align-top mt-2 ml-4 readable-length--medium"
      data-tc-header-form
    >
      <h2>{{ header }}</h2>
      <p v-html="subHeaderText" />
    </div>
  </div>
</template>

<script>
  import permissionsHelper from 'mixins/permissions_helper';

  export default {
    mixins: [permissionsHelper],

    props: {
      imageSrc: {
        required: true
      },
      header: {
        required: true
      },
      subHeader: {
        required: true
      },
      companyModule: {
        default: ''
      }
    },
    computed: {
      subHeaderText() {
        if (this.companyModule == "helpdesk" && this.isWrite) {
          return `${this.subHeader} You can also <a href="/help_tickets/settings/custom_forms" target="_blank">create a new ticket form</a>.`;
        }
        return this.subHeader;
      }
    }
  }
</script>