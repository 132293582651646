import http from 'common/http';

export default {
  data() {
    return {
      dependentTasks: [],
      selectedCompanyUserVendors: {},
    };
  },
  computed: {
    usersInAutomatedTask() {
      return this.dependentTasks?.length;
    },
  },
  methods: {
    derivedCompanyModule() {
      const { base } = this.$router.options.base;
      if (base !== '/') {
        if (base === '/help_tickets') {
          return "HelpTicket";
        } 
      } else if (document.location.pathname.startsWith("/help_tickets")) {
        return "HelpTicket";
      }
      return null;
    },
    fetchUserVendors() {
      const selectedCompanyUsersIds = this.selectedCompanyUsersArr ? this.selectedCompanyUsersArr.map((user) => user.id) : [this.userId || this.companyUser.id];

      http
        .get('/company_users/fetch_company_user_vendors.json', { params: { company_user_ids: selectedCompanyUsersIds } })
        .then((res) => {
          this.selectedCompanyUserVendors = res.data;
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error while fetching vendors. ${error.response.data.message}`);
        });
    },
    checkUserDependencies() {
      const selectedCompanyUsersIds = this.selectedCompanyUsersArr ? this.selectedCompanyUsersArr.map((user) => user.id) : this.userId || this.companyUser.id;
      http
        .get(`/company_users/${selectedCompanyUsersIds}/user_dependent_automated_task.json`, { params: { is_archive_modal: true } })
        .then(res => {
          this.dependentTasks = res.data.dependentTasks;
        });
    },
  },
};
