<template>
  <div 
    v-if="!isValidWorkspace"
    class="mx-auto pb-5 single-column-form-wrap mt-5"
  >
    <div class="box px-5 box__inner">
      <div class="mt-3">
        <h6>We're sorry, it appears you've entered an invalid URL for our Knowledge Base.</h6>
        <p class="not-as-small">Please double-check the URL or navigate back to Knowledge 
          Base by clicking <a href="/knowledge_base">here.</a> If you need further assistance, 
          feel free to contact our support team. Thank you for your understanding.</p>
      </div>
    </div>
  </div>
  <div v-else>
    <help-center-header
      :selected-workspace="selectedWorkspaceItem"
      :is-submit-request-route-disabled="hasNoFormInWorkspace"
      :is-basic-user-helpdesk="isBasicUserHelpdesk"
    >
      <div class="position-relative hc-text-header">
        <h1
          class="mt-5 font-weight-bold mb-4 help-center-title"
        >
          <img
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/article.png"
            height="96"
            class="mt-n5 mb-n3 ml-n5"
          >
          Knowledge Base
        </h1>
        <search-bar
          v-if="!isShowPage"
          class="mb-3"
          :article-search-enabled="true"
          suggested-text="Search our knowledge base articles..."
        />
        <div class="not-as-big mb-6">
          Check our internal guides for detailed help and info.
        </div>
      </div>
    </help-center-header>
    <div class="pb-7 position-relative readable-length--large mx-auto">
      <div class="row">
        <div class="col-8 pr-0">
          <div class="box px-5 pt-5 mt-n6 box--natural-height pb-0 help-center-main-content">
            <div class="box__inner">
              <div class="d-flex align-items-center mt-n3 mb-4 justify-content-between">
                <div class="d-flex">
                  <span class="smallest text-muted">Help Desk Workspace:</span>
                  <multi-select
                    name="workspaces"
                    placeholder="Select Workspace"
                    select-label=""
                    deselect-label=""
                    track-by="name"
                    label="name"
                    :value="selectedWorkspace"
                    :allow-empty="true"
                    :multiple="false"
                    :options="workspaces"
                    @select="(workspace) => selectWorkspace(workspace, true)"
                  />
                </div>
                <div 
                  class="d-flex align-items-start cursor-pointer" 
                  @click="$router.push(`/workspaces/${selectedWorkspace.id}`);"
                >
                  <i class="nulodgicon-arrow-left-c white mr-2"/>
                  <span>Back</span>
                </div>
              </div>
              <div 
                v-if="publicArticlesEnabled"
                class="d-flex flex-column pl-0 mb-4"
              >
                <h5 class="font-weight-normal">
                  What would you like to know?
                </h5>
                <div 
                  v-if="computedUsedCategories"
                  class="mb-n2 form-group"
                >
                  <label
                    v-for="(category, index) in computedUsedCategories"
                    :key="`${index}-category`"
                    class="border-300 mr-2 mb-2 cursor-pointer help-center-custom-pill truncate"
                    :class="[
                      computedUsedCategories.length <= categoryResizeThreshold ? 'py-2 px-3' : 'py-1 px-2', 
                      isInSelectedCategories(category) ? 'hc-bg-button' : 'bg-white text-dark'
                    ]"
                    @click="selectCategory(category)"
                  >
                    {{ category}}
                  </label>
                </div>
                <pulse-loader
                  v-else
                  size="0.5rem"
                  :color="helpCenterHyperlinksColor"
                  :loading="true"
                />
              </div>
              <div>
                <hr class="my-2" >
                <section
                  v-if="publicArticles && publicArticles.length > 0 && !loading"
                  class="pb-5 mt-4"
                >
                  <div
                    v-for="(article, index) in publicArticles"
                    :key="`${index}-article`"
                    class="open-article-item py-1 mb-4"
                    tabindex="0"
                    @keyup.enter="selectArticle(article)"
                    @click="selectArticle(article)"
                  >
                    <pulse-loader
                      v-if="loadingArticle && articleIdToShowLoader === article.id"
                      size="0.5rem"
                      :color="helpCenterHyperlinksColor"
                      :loading="true"
                    />
                    <article-item
                      class="clickable"
                      :class="{'clickable-hover p-2 mx-n2 mt-n1 rounded': false}"
                      :article-obj="article"
                      :truncate-title="false"
                      :truncate-body="true"
                      compact
                    />
                    <i class="open-article-icon nulodgicon-chevron-right d-none btn btn-info text-white btn-xs rounded-circle" />
                  </div>
                </section>
                <pulse-loader
                  v-else-if="loading"
                  class="py-2"
                  size="0.5rem"
                  :color="helpCenterHyperlinksColor"
                  :loading="true"
                />
                <div
                  v-else-if="!loading"
                  class="my-5 text-center w-100"
                  data-tc-no-article
                >
                  <h4 class="text-secondary">
                    No article found.
                  </h4>
                </div>
                <div
                  v-if="publicArticlesPageCount > 1"
                  class="row mt-sm-1 mt-md-2"
                >
                  <div class="col-12 d-flex align-items-center justify-content-end">
                    <paginate
                      ref="paginate"
                      class="mb-0"
                      :click-handler="pageSelected"
                      :container-class="'pagination pagination-sm'"
                      :next-class="'next-item'"
                      :next-link-class="'page-link'"
                      :next-text="'Next'"
                      :page-class="'page-item'"
                      :page-count="publicArticlesPageCount"
                      :page-link-class="'page-link'"
                      :prev-class="'prev-item'"
                      :prev-link-class="'page-link'"
                      :prev-text="'Prev'"
                      :selected="publicArticlesPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isShowPage"
          class="col text-right pl-0"
        >
          <div class="mt-6 mx-n7 ">
            <img 
              class="help-center-image"
              src="https://nulodgic-static-assets.s3.amazonaws.com/images/help_center.png"
            >
          </div>
        </div>
      </div>
    </div>

    <notifications position="bottom right" />
  </div>
</template>
<script>
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import permissionsHelper from 'mixins/permissions_helper';
  import _debounce from 'lodash/debounce';
  import ArticleItem from "components/knowledge_base/article_help_center_portal.vue";
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import Paginate from 'vuejs-paginate';
  import MultiSelect from 'vue-multiselect';
  import articles from 'mixins/articles';
  import HelpCenterHeader from './help_center_header.vue';
  import SearchBar from './search_bar.vue';

  export default {
    components: {
      PulseLoader,
      ArticleItem,
      Paginate,
      HelpCenterHeader,
      SearchBar,
      MultiSelect,
    },
    mixins: [helpCenterPortalHelper, permissionsHelper, articles],
    props: {
      workspaces: {
        type: Array,
        default: () => ([]),
      },
      isBasicUserHelpdesk: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedWorkspace: getWorkspaceFromStorage(),
        selectedCategories: ['Everything'],
        categoryResizeThreshold: 8,
        searchString: '',
        finishSearch: false,
        baseRoute:'/knowledge_base',
        loadingArticle: false,
        articleIdToShowLoader: null,
        isValidWorkspace: true,
        isWorkspaceChanged: false,
        isArticleRoute: false,
      };
    },
    computed: {
      ...mapGetters([
        'helpCenterBGColor',
        'helpCenterHyperlinksColor',
        'publicArticles',
        'publicArticlesPageCount',
        'publicArticlesPage',
        'loading',
        'categories',
        'publicArticlesEnabled',
        'helpCenterSelectedArticle',
      ]),
      isShowPage() {
        return this.$route.name === 'help_center_knowledge_base_show';
      },
      computedUsedCategories() {
        const categoryNames = this.categories.map(category => category.name);
        return ['Everything', ...categoryNames];
      },
      filteredCategories() {
        return  this.selectedCategories.includes('Everything') ? null : this.selectedCategories;
      },
    },
    watch: {
      workspaces() {
        if (this.workspaces.length && this.isBasicUserHelpdesk) {
          this.setArticles();
        }
      },
      helpCenterSelectedArticle(newValue) {
        if (Object.keys(newValue).length) {
          if (!this.isArticleRoute) {
            this.$router.push(`${this.baseRoute}/${this.helpCenterSelectedArticle.slug}/${this.selectedWorkspace.id}`);
          }
        } else {
          this.isArticleRoute = false;
          this.$router.push(`${this.baseRoute}/workspaces/${this.selectedWorkspace.id}`);
        }
      },
    },
    mounted() {
      this.setArticles();
    },
    methods: {
      ...mapActions([
        'fetchCategories',
        'fetchPublicArticles',
        'fetchHelpCenterColors',
      ]),
      ...mapMutations([
        'setPublicArticles',
        'setPublicArticlesPage',
        'setPublicArticlesPageCount',
        'setPublicArticlesCategories',
        'setLoading',
      ]),
      isInSelectedCategories(category) {
        return this.selectedCategories.includes(category);
      },
      setArticles() {
        if (this.isBasicUserHelpdesk) {
          this.baseRoute = '/knowledge';
        }
        if (this.workspaces.length ) {
          const workspace = this.workspaces.find(w => w.id === parseInt(this.$route.params.workspace_id, 10));
          if (!workspace?.id) {
            this.isValidWorkspace = false;
            return;
          }
          if (workspace?.id) {
            this.selectWorkspace(workspace, false);
          }
        }
        const ArticleSlug = this.$route.params.slug;
        if (ArticleSlug) {
          this.isArticleRoute = true;
          this.$router.push(`${this.baseRoute}/${ArticleSlug}/${this.selectedWorkspace.id}`);
        }
      },
      selectWorkspace(workspace, workspaceChanged) {
        this.isWorkspaceChanged = workspaceChanged;
        this.selectedWorkspace = workspace;
        setWorkspaceToStorage(workspace);
        this.fetchHelpCenterColors().then(() => {
          this.fetchCategories({ knowledgeBase: true });
          this.setLoading(true);
          this.loadArticles();
        });
        if (this.isWorkspaceChanged) {
          this.$router.push(`${this.baseRoute}/workspaces/${workspace.id}`);
        }
      },
      selectArticle(article) {
        this.articleIdToShowLoader = article.id;
        this.loadingArticle = true;
        this.$router.push(`${this.baseRoute}/${article.slug}/${article.workspaceId}`);
      },
      selectCategory(category) {
        this.setLoading(true);
        if (category === 'Everything') {
          this.selectedCategories = ['Everything'];
        } else if (!this.selectedCategories.includes(category)) {
          if (this.selectedCategories.includes('Everything')) {
            this.selectedCategories.splice(this.selectedCategories.indexOf('Everything'), 1);
          }
          this.selectedCategories.push(category);
        } else {
          const index = this.selectedCategories.indexOf(category);
          if (index !== -1) {
            this.selectedCategories.splice(index, 1);
          }
        }
        if (this.selectedCategories.length === 0) {
          this.selectedCategories = ['Everything'];
        }
        this.setPublicArticlesCategories(this.filteredCategories);
        this.loadArticles();
      },
      loadArticles: _debounce(function loadArticles(closeDropdown) {
        this.finishSearch = closeDropdown;
        this.setPublicArticlesPage(0);
        this.fetchPublicArticles({ search: this.searchString, categories: this.filteredCategories });
      }, 500),
      pageSelected(p) {
        this.setPublicArticlesPage(p - 1);
        this.fetchPublicArticles({ search: this.searchString, category: this.filteredCategories });
      },
    },
  };
</script>

